
import { partnerorderService } from '../services';
import baseMethods from './base';

const initialState = {  partnerordersForMe: {data: null,status:{ loading: false,ready: false }},
                        partnerorders: {data: null,status:{ loading: false,ready: false }},
                        partnerorder: {data: null,status:{ loading: false,ready: false }}
                     };

export const partnerorder = {
    namespaced: true,
    state: initialState,
    actions: {
        getPartnerOrders({ commit } )
        {
            commit('getPartnerOrdersRequest' );
            return new Promise((resolve, reject) => {

                partnerorderService.getMyPartnerOrders().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPartnerOrdersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPartnerOrdersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getPartnerOrdersForMe({ commit } )
        {
            commit('getPartnerOrdersForMeRequest' );
            return new Promise((resolve, reject) => {

                partnerorderService.getPartnerOrdersForMe().then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPartnerOrdersForMeSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPartnerOrdersForMeFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deletePartnerOrder({ commit }, id)
        {
            commit('deletePartnerOrderRequest' );
            return new Promise((resolve, reject) => {
                
                partnerorderService.deletePartnerOrder(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deletePartnerOrderSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deletePartnerOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        
        // eslint-disable-next-line no-unused-vars
        mergePartnerOrder({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                partnerorderService.mergePartnerOrder(data).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        deletePartnerOrderMember({ commit }, id)
        {
            commit('deletePartnerOrderMemberRequest' );
            return new Promise((resolve, reject) => {
                
                partnerorderService.deletePartnerOrderMember(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deletePartnerOrderMemberSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deletePartnerOrderMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getPartnerOrder({ commit }, id)
        {
            commit('getPartnerOrderRequest' );
            return new Promise((resolve, reject) => {
                
                partnerorderService.getPartnerOrder(id).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getPartnerOrderSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getPartnerOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },      
        savePartnerOrder({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertPartnerOrderRequest' );
                    partnerorderService.insertPartnerOrder(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertPartnerOrderSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertPartnerOrderFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updatePartnerOrderRequest' );
                    partnerorderService.updatePartnerOrder(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updatePartnerOrderSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updatePartnerOrderFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        savePartnerOrderMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertPartnerOrderMemberRequest' );
                    partnerorderService.insertPartnerOrderMember(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertPartnerOrderMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertPartnerOrderMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updatePartnerOrderMemberRequest' );
                    partnerorderService.updatePartnerOrderMember(data).then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updatePartnerOrderMemberSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updatePartnerOrderMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        convertToOffer({ commit }, data )
        {
            commit('convertToOfferRequest' );
            return new Promise((resolve, reject) => {

                partnerorderService.convertToOffer(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('convertToOfferSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('convertToOfferFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        lockPartnerOrder({ commit }, data )
        {
            commit('lockPartnerOrderRequest' );
            return new Promise((resolve, reject) => {

                partnerorderService.lockPartnerOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('lockPartnerOrderSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('lockPartnerOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },     
        isPartnerOrderLocked({ commit }, id )
        {
            commit('isPartnerOrderLockedRequest' );
            return new Promise((resolve, reject) => {

                partnerorderService.isPartnerOrderLocked(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('isPartnerOrderLockedSuccess',response.data);
                            var resp = {isLocked:false,username:""};

                            if(response.data.txt1.length > 0)
                            {
                                resp.isLocked = true;
                                resp.username = response.data.txt2;
                                
                            }
                            resolve(resp);
                        }
                        else
                        {
                            commit('isPartnerOrderLockedFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        reorderPartnerOrderMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                commit('reorderPartnerOrderMemberRequest' );
                partnerorderService.reorderPartnerOrderMember(data).then(
                response => {
                    
                    if(baseMethods.IsSuccess(response))
                    {
                        commit('reorderPartnerOrderMemberSuccess',data);
                        resolve(response.data);
                    }
                    else
                    {
                        commit('reorderPartnerOrderMemberFailure');
                        reject(response.statusText);
                    }
                }
                );
            });
        },
    },
    mutations: {
        getPartnerOrdersRequest(state)
        {
            state.partnerorders = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPartnerOrdersSuccess(state, data)
        {
            state.partnerorders = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPartnerOrdersFailure(state)
        {
            state.partnerorders = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getPartnerOrdersForMeRequest(state)
        {
            state.partnerordersForMe = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPartnerOrdersForMeSuccess(state, data)
        {
            state.partnerordersForMe = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPartnerOrdersForMeFailure(state)
        {
            state.partnerordersForMe = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deletePartnerOrderRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        deletePartnerOrderSuccess(state,id)
        {
            state.partnerorders.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerorders.data.length;i++)
            {
                if(state.partnerorders.data[i].id == id)
                {
                    state.partnerorders.data.splice(i,1);
                }
            }
        },
        deletePartnerOrderFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },
        deletePartnerOrderMemberRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        deletePartnerOrderMemberSuccess(state,id)
        {
            state.partnerorders.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerorders.data.length;i++)
            {
                for(var j = 0; j < state.partnerorders.data[i].member.length; j++)
                {
                    if(state.partnerorders.data[i].member[j].id == id)
                    {
                        state.partnerorders.data[i].member.splice(j,1);
                        break;
                    }
                }
            }
        },
        deletePartnerOrderMemberFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },
        insertPartnerOrderRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        insertPartnerOrderSuccess(state)
        {
            state.partnerorders.status = { loading: false,ready: true };
        },
        insertPartnerOrderFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },
        updatePartnerOrderRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        updatePartnerOrderSuccess(state,data)
        {
            state.partnerorders.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerorders.data.length;i++)
            {
                if(state.partnerorders.data[i].id == data.id)
                {
                    var tmp = JSON.stringify(data);
                    state.partnerorders.data[i] = JSON.parse(tmp);
                }
            }
        },
        updatePartnerOrderFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },  
        insertPartnerOrderMemberRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        insertPartnerOrderMemberSuccess(state)
        {
            state.partnerorders.status = { loading: false,ready: true };
        },
        insertPartnerOrderMemberFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },
        updatePartnerOrderMemberRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        updatePartnerOrderMemberSuccess(state,data)
        {
            state.partnerorders.status = { loading: false,ready: true };
            for(var i =0; i < state.partnerorders.data.length;i++)
            {
                for(var j = 0; j < state.partnerorders.data[i].member.length; j++)
                {
                    if(state.partnerorders.data[i].member[j].id == data.id)
                    {
                        var tmp = JSON.stringify(data);
                        state.partnerorders.data[i].member[j] = JSON.parse(tmp);
                        break;
                    }
                }
            }
        },
        updatePartnerOrderMemberFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },      
        lockPartnerOrderRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        lockPartnerOrderSuccess(state,data)
        {
            state.partnerorders.status = { loading: false,ready: false };
            if(state.partnerorders.data != null)
            {
                for(var i = 0; i < state.partnerorders.data.length; i++)
                {
                    if(state.partnerorders.data[i].id == data.id)
                    {
                        state.partnerorders.data[i].lockedByUserGuid = "";
                        state.partnerorders.data[i].lockedByUsername = "";
                        if(data.txt1.length > 0)
                        {
                            state.partnerorders.data[i].lockedByUserGuid = data.txt1;
                            state.partnerorders.data[i].lockedByUsername = data.txt2;
                        }
                        break;
                    }
                }
            }
        },
        lockPartnerOrderFailure(state)
        {
            state.partnerorders.status = { loading: false,ready: false };
        },
        isPartnerOrderLockedRequest(state)
        {
            state.partnerorders.status = { loading: true,ready: false };
        },
        isPartnerOrderLockedSuccess(state,data)
        {
            state.partnerorders.status = { loading: false,ready: false };
            for(var i = 0; i < state.partnerorders.data.length; i++)
            {
                if(state.partnerorders.data[i].id == data.id)
                {
                    state.partnerorders.data[i].lockedByUserGuid = "";
                    state.partnerorders.data[i].lockedByUsername = "";
                    if(data.txt1.length > 0)
                    {
                        state.partnerorders.data[i].lockedByUserGuid = data.txt1;
                        state.partnerorders.data[i].lockedByUsername = data.txt2;
                    }
                    break;
                }
            }
        },
        isPartnerOrderLockedFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        getPartnerOrderRequest(state)
        {
            state.partnerorder = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getPartnerOrderSuccess(state, data)
        {
            state.partnerorder = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getPartnerOrderFailure(state)
        {
            state.partnerorder = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        reorderPartnerOrderMemberRequest(state)
        {
            state.partnerorder.status = { loading: true,ready: false };
        },
        reorderPartnerOrderMemberSuccess(state)
        {
            state.partnerorder.status = { loading: false,ready: true };
        },
        reorderPartnerOrderMemberFailure(state)
        {
            state.partnerorder.status = { loading: false,ready: false };
        },
        convertToOfferRequest(state)
        {
            state.partnerordersForMe.status = { loading: true,ready: false };
        },
        convertToOfferSuccess(state)
        {
            state.partnerordersForMe.status = { loading: false,ready: true };
        },
        convertToOfferFailure(state)
        {
            state.partnerordersForMe.status = { loading: false,ready: false };
        }
    }
}
