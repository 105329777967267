import Vue from 'vue';
import Router from 'vue-router';
import LocalStorageService from './services/localstorage.service';
import {ability} from '@/helper';

Vue.use(Router);
const localStorageService = LocalStorageService.getService();

export const router = new Router({

	routes: [
    {
    // ======================
    // Full Layout
    // ======================
    
			path: '',
            component: () => import('./layout/full/MainContainer.vue'),
            index:1,
            // ======================
            // Theme routes / pages
            // ======================

            children: [
                {
                    path: '/',
                    redirect: '/dashboard'
                },
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    index: 1,
                    component: () => import('./views/Dashboard.vue')//Hier kein Userrecht abfragen sonst gibts ein endlosredirect weil '/' hierher umgeleitet wird
                },
                {
                    path: '/useradmin',
                    name: 'UserAdmin',
                    index: 2,
                    component: () => import('./views/UserAdmin.vue'),
                    meta:{action:'show',section: 'user'}
                },
                {
                    path: '/groupadmin',
                    name: 'GroupAdmin',
                    index: 4,
                    component: () => import('./views/GroupAdmin.vue'),
                    meta:{action:'show',section: 'group'}
                },
                {
                    path: '/translations',
                    name: 'Translations',
                    index: 5,
                    component: () => import('./views/Translations.vue'),
                    meta:{action:'show',section: 'translations'}
                },
                {
                    path: '/mainsettings',
                    name: 'MainSettings',
                    index: 6,
                    component: () => import('./views/MainSettings.vue'),
                    meta:{action:'show',section: 'mainsettings'}
                },
                {
                    path: '/messages',
                    name: 'Messages',
                    index: 7,
                    component: () => import('./views/Messages.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/myaddresses',
                    name: 'MyAddresses',
                    index: 8,
                    component: () => import('./views/MyAddresses.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/bankaccounts',
                    name: 'BankAccounts',
                    index: 9,
                    component: () => import('./views/BankAccounts.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/companyadmin',
                    name: 'CompanyAdmin',
                    index: 10,
                    component: () => import('./views/CompanyAdmin.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/productadmin',
                    name: 'ProductAdmin',
                    index: 11,
                    component: () => import('./views/ProductAdmin.vue'),
                    meta:{action:'show',section: 'productadmin'}
                },
                {
                    path: '/countryadmin',
                    name: 'CountryAdmin',
                    index: 12,
                    component: () => import('./views/CountryAdmin.vue'),
                    meta:{action:'show',section: 'countryadmin'}
                },
                {
                    path: '/pricelists',
                    name: 'Pricelists',
                    index: 13,
                    component: () => import('./views/Pricelists.vue'),
                    meta:{action:'show',section: 'pricelists'}
                },
                {
                    path: '/currencyadmin',
                    name: 'CurrencyAdmin',
                    index: 14,
                    component: () => import('./views/CurrencyAdmin.vue'),
                    meta:{action:'show',section: 'currencyadmin'}
                } ,
                {
                    path: '/documents',
                    name: 'Documents',
                    index: 15,
                    component: () => import('./views/Documents.vue'),
                    meta:{action:'show',section: 'documents'}
                },
                {
                    path: '/prices',
                    name: 'Prices',
                    index: 16,
                    component: () => import('./views/Prices.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/debitors',
                    name: 'Debitors',
                    index: 17,
                    component: () => import('./views/Debitors.vue'),
                    meta:{action:'show',section: 'debitors'}
                },
                {
                    path: '/debitorcontacts',
                    name: 'DebitorContacts',
                    index: 18,
                    component: () => import('./views/DebitorContacts.vue'),
                    meta:{action:'show',section: 'debitors'}
                },
                {
                    path: '/offers',
                    name: 'Offers',
                    index: 19,
                    component: () => import('./views/Offers.vue'),
                    meta:{action:'show',section: 'debitors'}
                },
                {
                    path: '/fixtext',
                    name: 'Fixtext',
                    index: 20,
                    component: () => import('./views/Fixtext.vue'),
                    meta:{action:'show',section: 'fixtext'}
                },
                {
                    path: '/productgroups',
                    name: 'Produktgruppen',
                    index: 21,
                    component: () => import('./views/Productgroups.vue'),
                    meta:{action:'show',section: 'productadmin'}
                },
                {
                    path: '/payments',
                    name: 'Zahlungen',
                    index: 22,
                    component: () => import('./views/Payments.vue'),
                    meta:{action:'show',section: 'payment'}
                },
                {
                    path: '/deliveryconditions',
                    name: 'Lieferbedingungen',
                    index: 23,
                    component: () => import('./views/DeliveryConditions.vue'),
                    meta:{action:'show',section: 'deliverycondition'}
                },
                {
                    path: '/paymentconditions',
                    name: 'Zahlungsbedingungen',
                    index: 24,
                    component: () => import('./views/PaymentConditions.vue'),
                    meta:{action:'show',section: 'deliverycondition'}// Selbes REcht wie Lieferbedingungen
                },
                {
                    path: '/debitorcontact/:debitorcontact_fk',
                    name: 'DebitorContact',
                    index: 25,
                    component: () => import('./views/DebitorContact.vue'),
                    meta:{action:'show',section: 'debitors'}
                },
                {
                    path: '/offer/:offer_fk',
                    name: 'Offer',
                    index: 26,
                    component: () => import('./views/components/Offer.vue'),
                    meta:{action:'show',section: 'offer'}
                },
                {
                    path: '/order/:order_fk',
                    name: 'Order',
                    index: 27,
                    component: () => import('./views/components/Order.vue'),
                    meta:{action:'show',section: 'order'}
                },
                {
                    path: '/companygroupadmin',
                    name: 'CompanyGroupAdmin',
                    index: 28,
                    component: () => import('./views/CompanyGroupAdmin.vue'),
                    meta:{action:'show',section: 'companygroup'}
                },
                {
                    path: '/motifadmin',
                    name: 'MotifAdmin',
                    index: 29,
                    component: () => import('./views/MotifAdmin.vue'),
                    meta:{action:'show',section: 'motif'}
                },
                {
                    path: '/partnerorders',
                    name: 'PartnerOrders',
                    index: 30,
                    component: () => import('./views/PartnerOrders.vue'),
                    meta:{action:'show',section: 'partnerorder'}
                },
                {
                    path: '/partnerorder/:partnerorder_fk',
                    name: 'PartnerOrder',
                    index: 31,
                    component: () => import('./views/components/PartnerOrder.vue'),
                    meta:{action:'show',section: 'partnerorder'}
                },
                {
                    path: '/partnerordersforme',
                    name: 'PartnerOrdersForMe',
                    index: 32,
                    component: () => import('./views/PartnerOrdersForMe.vue'),
                    meta:{action:'show',section: 'partnerorderforme'}
                },
                {
                    path: '/partnerdiscounts',
                    name: 'PartnerDiscounts',
                    index: 33,
                    component: () => import('./views/PartnerDiscounts.vue'),
                    meta:{action:'show',section: 'partnerdiscount'}
                },
                {
                    path: '/specialdiscounts',
                    name: 'SpecialDiscounts',
                    index: 34,
                    component: () => import('./views/SpecialDiscounts.vue'),
                    meta:{action:'show',section: 'partnerdiscount'}
                },
                {
                    path: '/documentqueue',
                    name: 'DocumentQueue',
                    index: 35,
                    component: () => import('./views/DocumentQueue.vue'),
                    meta:{action:'show',section: 'documentqueue'}
                },
                {
                    path: '/testcalls',
                    name: 'TestCalls',
                    index: 36,
                    component: () => import('./views/TestCalls.vue'),
                    meta:{action:'admin',section: 'admin'}
                },
                {
                    path: '/timerecs',
                    name: 'TimeRecs',
                    index: 37,
                    component: () => import('./views/TimeRecs.vue'),
                    meta:{action:'show',section: 'timerec'}
                },
                {
                    path: '/timemodels',
                    name: 'TimeModels',
                    index: 38,
                    component: () => import('./views/TimeModels.vue'),
                    meta:{action:'show',section: 'timerec'}
                },
                {
                    path: '/confirmations',
                    name: 'Confirmations',
                    index: 39,
                    component: () => import('./views/Confirmations.vue'),
                    meta:{action:'show',section: 'common'}
                },
                {
                    path: '/downloads',
                    name: 'Downloads',
                    index: 40,
                    component: () => import('./views/Downloads.vue'),
                    meta:{action:'show',section: 'download'}
                },
                {
                    path: '/invoices',
                    name: 'Invoices',
                    index: 41,
                    component: () => import('./views/InvoicesOverview.vue'),
                    meta:{action:'show',section: 'invoice'}
                },
                
            ]
		},

        {
            path: '/login',
            name: 'Login',
            index: 2,
            component: () => import('./layout/login/LoginPage.vue') 
        },
        {
            path: '/resetpassword',
            name: 'ResetPassword',
            index: 3,
            component: () => import('./layout/login/ResetPasswordPage.vue'),
            props: { AllowCancel: true } ,
            meta:{action:'show',section: 'common'}
        }, 
        {
            path: '/changepassword',
            name: 'ChangePassword',
            index: 4,
            component: () => import('./layout/login/ResetPasswordPage.vue'),
            props: { AllowCancel: false } ,
            meta:{action:'show',section: 'common'}
        },         
        {
            path: '*',
            redirect: '/dashboard'
        }
    ]

});
 
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login','/assets/images'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorageService.getAuthToken();
    const resetPwd = localStorageService.getResetPwd();

    var canNavigate = true;
    if(typeof(to.meta.section) !== 'undefined')
        canNavigate = ability.can(to.meta.action, to.meta.section);

    if (authRequired && !loggedIn) {
        next('/login');
    }
    else
    {
        if(loggedIn && resetPwd === "true" && to.path !== "/changepassword"&& to.path !== "/resetpassword")
        {
          next('/changepassword');
        }
        else
        {
            if(canNavigate)
                next();
            else
                next('/');// benutzer eingeloggt, darf aber laut userrecht nicht auf die url
        }
    }
}) 