import {baseAxios} from '../helper/axiosHelper';

export const partnerorderService = {
    insertPartnerOrder,
    insertPartnerOrderMember,
    getMyPartnerOrders,
    getPartnerOrdersForMe,
    updatePartnerOrder,
    updatePartnerOrderMember,
    deletePartnerOrder,
    deletePartnerOrderMember,
    lockPartnerOrder,
    isPartnerOrderLocked,
    getPartnerOrder,
    reorderPartnerOrderMember,
    convertToOffer,
    mergePartnerOrder
};

function insertPartnerOrder(d) {

    if(d.from_offer_fk > 0)
    {
        return baseAxios.post('partnerorder/PartnerOrder/offer',d)
        .then((response) =>
        {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    }
    else
    {
        return baseAxios.post('partnerorder/PartnerOrder',d)
        .then((response) =>
        {
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
    }
}
function updatePartnerOrder(d) {

    return baseAxios.put('partnerorder/PartnerOrder',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deletePartnerOrder(id) {

    return baseAxios.delete('partnerorder/PartnerOrder',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function mergePartnerOrder(d) {

    return baseAxios.put('partnerorder/PartnerOrder/merge',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertPartnerOrderMember(d) {

    return baseAxios.post('partnerorder/PartnerOrdermember',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updatePartnerOrderMember(d) {

    return baseAxios.put('partnerorder/PartnerOrdermember',d)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deletePartnerOrderMember(id) {

    return baseAxios.delete('partnerorder/PartnerOrdermember',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getMyPartnerOrders() {

    return baseAxios.get('partnerorder/partnerorder/my')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getPartnerOrder(id) {

    return baseAxios.get('partnerorder/partnerorder',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getPartnerOrdersForMe() {

    return baseAxios.get('partnerorder/partnerorder/forme')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function lockPartnerOrder(data) {

    return baseAxios.put('partnerorder/partnerorder/lock',data  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function convertToOffer(data) {

    return baseAxios.put('partnerorder/partnerorder/convert',data  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function isPartnerOrderLocked(id) {

    return baseAxios.get('partnerorder/partnerorder/lock',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function reorderPartnerOrderMember(data) {

    return baseAxios.put('partnerorder/PartnerOrdermember/reorder',data  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

